import React, { useEffect, useState } from 'react';
import Title from '../Components/title';

const CountrySelect = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    // Fetch list of countries
    fetch(
      'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code'
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);

        // Get user's location based on geolocation
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;

          // Use latitude and longitude to fetch user's country code
          fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
            .then((response) => response.json())
            .then((locationData) => {
              const userCountryCode = locationData.countryCode;
              setSelectedCountry(userCountryCode);
            })
            .catch((error) => {
              console.error('Error fetching user location:', error);
            });
        });
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  return (
    <select
      name='country' // Set the name attribute for form submission
      value={selectedCountry}
      onChange={(e) => setSelectedCountry(e.target.value)}
    >
      {countries.map((country) => (
        <option key={country.value} value={country.value}>
          {country.label}
        </option>
      ))}
    </select>
  );
};


function Team() {
  const submitForm = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const res = await fetch('/php/join.php', {
        method: 'POST',
        body: formData,
      });

      const text = await res.text();

      console.log(text);
      if (text.trim() == 'success') {
          alert('Form Submitted Successfully!')                
      }
    } catch (error) {
      console.error('Failed to submit the form', error);
    }
  };

  return (
    <>
      <div className='hero_section'>
        <Title titleText="Join the 3D King team" customClass="team_title"></Title>
        <p>
          Join 3D Kings and say goodbye to large fees and time-consuming client interactions. Our dedicated managers handle all communications and logistics, allowing you to concentrate fully on your 3D projects. Plus, benefit from receiving 85% of the total order price with quick and easy payment withdrawals
        </p>
        <form className='form' onSubmit={submitForm}>
          <div className='inputs'>
            <div className='input_container input-half'>
              <input type='email' name='email' placeholder='Email Address' /> 
            </div>
            <div className='input_container input-half'>
              <CountrySelect />
            </div>
          </div>
          <div className='input_container'>
            <textarea placeholder='Tell us about your 3D specialization' name='message'></textarea> 
          </div>
          <button type='submit'>Submit</button>
        </form>
      </div>
    </>
  );
}

export default Team;
