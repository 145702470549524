import React from "react";

function Contact() {
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <>
      <div className="contactPage">
        <a href="#" className="back" onClick={handleBackClick}>
          <i className="fa-solid fa-angle-left"></i> Back
        </a>
        <h1><a href="mailto:King3D@vrkingcgi.com">King3D@vrkingcgi.com</a></h1>
      </div>
    </>
  );
}

export default Contact;