import React, { useEffect } from "react";
import { gsap } from "gsap";
import SplitType from 'split-type'

const Title = ({ titleText, customClass })=> {
    useEffect(() => {
        // Title animation 
        let pageTitle = new SplitType(`.${customClass}`);
        gsap.to(`.${customClass} .char`, {
            x: 0,
            opacity: 1,
            stagger: 0.07,
            delay: 0.01,
            duration: 0.05,
        });
    }, [customClass]);
    const combinedClass = `${customClass} title_component`;

    return (
        <>
            <h1 className={combinedClass}>{titleText}</h1>
        </>
    )
}

export default Title;