import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Title from '../Components/title';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CGIVideo from '../Components/video';
gsap.registerPlugin(ScrollTrigger); 

/*----------------------|
|      Import Images    |
|----------------------*/


function Home() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      setIsLoaded(true);
    }, []);

    /*-------------- inactive ----------------------*/
    /*------------/* SCROLL SNAPPING /*------------*/
    // useEffect(() => {
    //     const video_banner = gsap.utils.toArray('.video_banner')
    //     video_banner.forEach(v_banner => {
    //         gsap.to(v_banner, {
    //             opacity: 1,
    //             scrollTrigger: {
    //                 trigger: v_banner, 
    //                 snap: true,
    //                 start: "top bottom",
    //                 end: "bottom bottom", 
    //             }
    //         })
    //         const allTitles = v_banner.querySelectorAll('h2');
    //         allTitles.forEach(s_title => {
    //             gsap.to(s_title, {
    //                 y: 0,
    //                 opacity: 1,
    //                 duration: 1, 
    //                 scrollTrigger: {
    //                     trigger: v_banner, 
    //                     start: "top 70%",
    //                     end: "bottom 20%", 
    //                 }
    //             });
    //         });
    //     }); 
    //   }, []);

    return (
        <div className='homePage'>
            <div className="hero_section">
                <Title titleText="3D and AI services for all of your needs" customClass="h1"></Title>
                {/* <p>What can we help you with Today?</p> */}
                <nav className="inpage_nav">
                    <a href="#3d_games">3d games</a>
                    <a href="#3d_films">3d film</a>
                    <a href="#ai_services">ai services</a>
                    <a href="#music_visualizers">music visualizers</a>
                    <a href="#vfx">vfx</a>
                    <a href="#3d_products">3d products</a>
                    <a href="#vr">VR</a>
                    <a href="#architecture">ARCHITECTURE</a>
                </nav> 
            </div>
            <Link to='/service' className="video_banner">
                <div className="column" id='3d_games'>
                    <h2>3D GAMES</h2>
                    <CGIVideo publicId="3DGameVix_cixfag" />
                </div>
                <div className="column" id='3d_films'>
                    <h2>3D FILM</h2>
                    <CGIVideo publicId="3DFilmVix_b4kmqz" />
                </div>
            </Link>  
            <Link to='/service' className="video_banner">
                <div className="column" id='ai_services'>
                    <h2>AI SERVICES</h2>
                    <CGIVideo publicId="AIservicesVix_pftc8v" />
                </div>
                <div className="column" id='music_visualizers'>
                    <h2>MUSIC VISUALIZERS</h2>
                    <CGIVideo publicId="MusixVix_luuwap" />
                </div>
            </Link>  
            <Link to='/service' className="video_banner">
                <div className="column" id='vr'>
                    <h2>VR</h2>
                    <CGIVideo publicId="VRshaipeseashowcase_tpmk8s" />
                </div>
                <div className="column" id='architecture'>
                    <h2>Architecture</h2>
                    <CGIVideo publicId="Architecturevideo_w2f4j9" />
                </div>
            </Link>
            <Link to='/service' className="video_banner">
                <div className="column" id='vfx'>
                    <h2>VFX</h2>
                    <CGIVideo publicId="VFXvix_oab9fa" />
                </div>
                <div className="column" id='3d_products'>
                    <h2>3D PRODUCTS</h2>
                    <CGIVideo publicId="ProductShowcase_bmkqjd" />
                </div>
            </Link>
        </div>
    )
} 
export default Home