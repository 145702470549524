import React from "react";
import Navigation from "./Components/navigation";
import Footer from "./Components/footer";
import { Outlet, useLocation } from "react-router-dom";

const Root = () => { 
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== "/contact" && <Navigation />}
      <Outlet />
      {pathname !== "/contact" && <Footer />}
    </>
  );
};

export default Root;
