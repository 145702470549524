import React from 'react';
import { Link } from 'react-router-dom';

function footer() {
    return (
        <>
            <footer>
                <div className="left_side">
                    <label htmlFor="newsletter_input">Sign up for our Newsletter</label>
                    <form className="input_container">
                        <input type="email" id='newsletter_input' placeholder='Your Email Adress' />
                        <button><i class="fa-solid fa-arrow-right"></i></button>
                    </form>
                    <div className="location">
                        {/* <p>107 S Indiana Ave, <br /> Bloomington, IN 47405</p>
                        <a href="#">Get directions</a> */}
                    </div>
                </div>
                <div className="right_side">
                    <div className="footer_links">
                        <Link to={`/`} >
                            Home
                            <div className="arrow_icon">
                                <i class="fa-solid fa-arrow-right"></i>
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>
                        </Link>
                        <Link to={`/team`} >
                            Work
                            <div className="arrow_icon">
                                <i class="fa-solid fa-arrow-right"></i>
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>
                        </Link>
                        <Link to={`/contact`} >
                            Contact
                            <div className="arrow_icon">
                                <i class="fa-solid fa-arrow-right"></i>
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                    <div className='copy_right'>© CGI 2023. All rights reserved.</div>
                </div>
            </footer>
        </>
    )
}

export default footer;