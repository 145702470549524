import { React, useRef, useState } from 'react';
import Title from '../Components/title';

function Service() {
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = () => {
        const files = Array.from(fileInputRef.current.files);
        setSelectedFiles(files);
    };

    const getFilePreview = (file) => {
        if (file.type.startsWith('image/')) {
            return <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '100px' }} />;
        } else {
            return (
                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                    {file.name}
                </a>
            );
        }
    };

    const submitForm = async (event) => {
        event.preventDefault(); 

        const formData = new FormData(event.target); 

        try {
            const res = await fetch('/php/service.php', {
                method: 'POST',
                body: formData,
            });

            const text = await res.text();
            console.log(text.trim()); 
            if (text.trim() == 'success') {
                alert('Form Submitted Successfully!')                
            }
        } catch (error) {
            console.error('Failed to submit the form', error);
        }
    };

    return (
        <>
            <div className="hero_section">
                <Title titleText="Let us match you with a designer" customClass="design_title"></Title>
                <p>Kindly share all your requirements, and we'll get back to you within 12 hours with a dedicated designer and a personalized pricing plan.</p>
                <form className='form' onSubmit={submitForm}>
                    <div className="inputs">
                        <div className="input_container input-half">
                            <input type="email" name='email' placeholder='Email Address' /> 
                        </div>
                        <div className="input_container input-half">
                            <input type="file" name="attachments[]" multiple ref={fileInputRef} onChange={handleFileChange} />
                            <div className='images_preview'>
                                {selectedFiles.length > 0 && ( 
                                    selectedFiles.map((file, index) => (
                                        <div className='single_file' key={index}>
                                            {getFilePreview(file)}
                                        </div>
                                    )) 
                                )}
                            </div> 
                        </div>
                    </div>
                    <div className="input_container">
                        <textarea placeholder='Describe the requirement here' name='message'></textarea> 
                        <i className="fa-solid fa-envelope"></i>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>
    )
}

export default Service;